import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UrlsTO} from 'api/entities';

@Injectable({providedIn: 'root'})
export class SystemService {
  private apiVersion = 'api/v1/';

  constructor(private http: HttpClient) {}

  getURLs() {
    return this.http.get<UrlsTO>(this.apiVersion + 'system/urls');
  }
}
